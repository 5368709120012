<template>
  <HeaderBar />

  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
  </ButtonBackContainer>

  <section class="carvia-container">
    <div class="mb-6">
      <h1 class="text-2xl font-bold text-primary">{{ t('createCoupon') }}</h1>
    </div>

    <div>
      <CouponForm :submit-label="t('create')" @submit="onSubmit" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import type { Coupon } from '@/entities/coupons/coupon.entity';
import CouponForm from '../components/CouponForm.vue';
import { useCreateCoupon } from '@/queries/use-coupons';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const router = useRouter();

const createMutation = useCreateCoupon();

const onSubmit = (data: Partial<Coupon>) => {
  if (typeof data.remainingValue !== 'number') {
    return;
  }

  createMutation.mutate(
    {
      remainingValue: data.remainingValue,
      originalValue: data.originalValue ?? 0,
      isActive: data.isActive ?? true,
      customerData: data.customerData,
    },
    {
      onSuccess: (createdCoupon) => {
        router.replace({ name: 'couponEdit', params: { id: createdCoupon.id } });
      },
    },
  );
};
</script>

<i18n lang="json">
{
  "en": {
    "back": "Back",
    "createCoupon": "Create Coupon",
    "create": "Create"
  },
  "de": {
    "back": "Zurück",
    "createCoupon": "Gutschein erstellen",
    "create": "Erstellen"
  }
}
</i18n>
