<template>
  <div class="relative flex w-full flex-col space-y-4 bg-accent p-6">
    <div class="flex flex-col space-y-3">
      <Input v-model="damageDescription" :label="t('description')" :placeholder="t('damage')" />

      <div class="w-full space-y-3">
        <div class="flex items-center gap-6">
          <div class="relative flex items-center gap-3 pt-8">
            <h4 class="absolute top-0">{{ t('picture') }}</h4>
            <div v-if="isMobile">
              <CVButton
                class="w-full"
                :disabled="!!imageTaken || !!imageFile"
                @click.prevent="startDeviceCamera('imageCamera')"
              >
                {{ t('takePicture') }}
              </CVButton>

              <FileInput
                id="imageCamera"
                v-model="imageFile"
                capture="environment"
                class="hidden"
                accept="image/*"
                :disabled="!!imageTaken || imageFile"
                @change="catchImage($event)"
              />
            </div>
            <div v-if="isMobile">/</div>
            <FileInput
              v-model="imageFile"
              :disabled="!!imageTaken || imageFile"
              accept="image/*"
              data-testid="select-damage-image"
              @change="catchImage($event)"
            />
          </div>
          <div v-if="imageUrl" class="relative">
            <img :src="imageUrl" class="max-h-[120px] max-w-[150px]" />
            <button
              class="absolute right-1 top-1 h-6 w-6 text-red-500"
              @click.prevent="deleteImage()"
            >
              <XMarkIcon />
            </button>
          </div>
        </div>

        <div class="flex items-center gap-6">
          <div class="relative flex items-center gap-3 pt-8">
            <h4 class="absolute top-0">{{ t('video') }}</h4>
            <div v-if="isMobile">
              <CVButton
                class="w-full"
                :disabled="!!videoTaken || !!videoFile"
                @click.prevent="startDeviceCamera('videoCamera')"
              >
                {{ t('takeVideo') }}
              </CVButton>

              <FileInput
                id="videoCamera"
                v-model="videoFile"
                capture="environment"
                class="hidden"
                accept="video/*"
                :disabled="!!videoTaken || videoFile"
                @change="catchVideo($event)"
              />
            </div>
            <div v-if="isMobile">/</div>
            <FileInput
              v-model="videoFile"
              :disabled="!!videoTaken || videoFile"
              accept="video/*"
              @change="catchVideo($event)"
            />
          </div>
          <div v-if="videoUrl" class="relative">
            <video controls class="max-h-[120px] max-w-[150px]">
              <source :src="videoUrl" />
            </video>
            <button
              class="absolute right-1 top-1 h-6 w-6 text-red-500"
              @click.prevent="deleteVideo()"
            >
              <XMarkIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4>{{ t('mark') }}</h4>
      <MarkerContainer
        :marker-positions="damagePosition && [damagePosition]"
        hide-position-numbers
        @select-position="damagePosition = $event"
      />
    </div>
    <button class="fixed -top-2 right-2 h-7 w-7 bg-red-500 text-white" @click.prevent="close()">
      <XMarkIcon />
    </button>
    <div v-if="newDamage">
      <CVButton
        class="mt-2 w-full"
        :disabled="!((imageTaken || imageFile) && damagePosition && damageDescription)"
        :is-loading="isUploadingMedia || isSaving"
        @click.prevent="save"
      >
        {{ t('save') }}
      </CVButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import FileInput from '@/components/FileInput.vue';
import Input from '@/components/Input.vue';
import type { CreateDamageDto, Position } from '@/entities/damage.entity';
import { useI18n } from 'vue-i18n';
import { useCreateDamage } from '@/queries/use-damages';
import { useUploadDamageMedia } from '@/queries/use-upload';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { computed, ref, type Ref } from 'vue';
import MarkerContainer from './components/MarkerContainer.vue';

const props = defineProps<{
  carId: string;
  bookingId?: string;
}>();

const emit = defineEmits<{
  (e: 'onClose', newDamageId?: string): void;
}>();

const { t } = useI18n();
const imageFile = ref<File>();
const imageTaken = ref<File | null>(null);
const videoFile = ref<File>();
const videoTaken = ref<File | null>(null);
const imageUrl = computed(() => {
  if (imageTaken.value) {
    return URL.createObjectURL(imageTaken.value);
  }
  return '';
});
const videoUrl = computed(() => {
  if (videoTaken.value) {
    return URL.createObjectURL(videoTaken.value);
  }
  return '';
});

const isMobile =
  'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0
    ? true
    : window.matchMedia && window.matchMedia('(pointer:coarse)').matches
      ? true
      : 'orientation' in window
        ? true
        : false;

const startDeviceCamera = (id: string) => {
  document.getElementById(id)?.click();
};

const catchImage = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const file = target.files?.item(0);
  if (file) {
    imageTaken.value = file;
  }
};

const catchVideo = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const file = target.files?.item(0);
  if (file) {
    videoTaken.value = file;
  }
};

const deleteImage = () => {
  imageTaken.value = null;
  imageFile.value = undefined;
};

const deleteVideo = () => {
  videoTaken.value = null;
  videoFile.value = undefined;
};

const damagePosition: Ref<Position | undefined> = ref();
const damageDescription = ref('');
const newDamage = computed((): CreateDamageDto => {
  return {
    carId: props.carId,
    bookingId: props.bookingId ?? undefined,
    description: damageDescription.value,
    positionX: damagePosition.value?.positionX ?? -Infinity,
    positionY: damagePosition.value?.positionY ?? -Infinity,
  };
});

const { mutateAsync: createDamage, isPending: isSaving } = useCreateDamage();
const { mutateAsync: uploadMedia, isPending: isUploadingMedia } = useUploadDamageMedia();

const close = (newDamageId?: string) => {
  deleteImage();
  deleteVideo();
  damageDescription.value = '';

  if (damagePosition.value) {
    damagePosition.value.positionX = undefined;
    damagePosition.value.positionY = undefined;
    damagePosition.value = undefined;
  }
  emit('onClose', newDamageId);
};

const save = async () => {
  if (!newDamage.value) return;
  const uploadImageFile = imageTaken.value ?? imageFile.value;
  const uploadVideoFile = videoTaken.value ?? videoFile.value;
  if (!uploadImageFile) return;
  uploadImageFile.type;
  const imageResult = await uploadMedia({
    file: uploadImageFile,
    params: {
      carId: props.carId,
      formerFileName: uploadImageFile.name,
    },
  });
  const videoResult = uploadVideoFile
    ? await uploadMedia({
        file: uploadVideoFile,
        params: {
          carId: props.carId,
          formerFileName: uploadVideoFile.name,
        },
      })
    : undefined;

  const { id } = await createDamage({
    ...newDamage.value,
    imageFileKey: imageResult.fileKey,
    videoFileKey: videoResult?.fileKey,
  });
  close(id);
};
</script>

<i18n lang="json">
{
  "en": {
    "description": "Add short description of damage",
    "saveImage": "save image",
    "mark": "Mark the damage",
    "damages": "Damages",
    "damage": "Damage",
    "addDamage": "+ add damage",
    "newDamages": "New Damages",
    "cancelImage": "Cancel",
    "takePicture": "+ Take picture",
    "takeVideo": "+ Take video",
    "picture": "Picture",
    "video": "Video"
  },
  "de": {
    "description": "Kurze Beschreibung des Schadens hinzufügen",
    "saveImage": "Bild Speichern",
    "mark": "Markiere den Schaden",
    "damages": "Schäden",
    "damage": "Schaden",
    "addDamage": "+ Schaden hinzufügen",
    "newDamages": "Neue Schäden",
    "cancelImage": "erneut aufnehmen",
    "takePicture": "+ Bild aufnehmen",
    "takeVideo": "+ Video aufnehmen",
    "picture": "Bild",
    "video": "Video"
  }
}
</i18n>
