<template>
  <div class="grid grid-cols-2 items-start gap-4 p-6">
    <div class="grid gap-4">
      <LabeledText :label="t('created')">{{ createdDate }}</LabeledText>
      <div v-if="!isInEditMode" class="flex items-start justify-between gap-2">
        <LabeledText :label="t('description')">{{ damage.description }}</LabeledText>
      </div>
      <div v-else class="space-y-4">
        <Input v-model="description" :label="t('description')" />
      </div>
      <LabeledText :label="t('repair')">
        <CVButton
          v-if="damage.status === DamageStatus.OPEN"
          class="justify-self-start"
          :is-loading="updatingDamage"
          @click.prevent="setDamageStatusToRepaired"
          >{{ t('confirmRepair') }}</CVButton
        >
        <span v-else>{{ t('repairedAt') }} {{ repairedDate }}</span>
      </LabeledText>
    </div>
    <div class="flex flex-col gap-4">
      <MarkerContainer
        :marker-positions="[position]"
        hide-position-numbers
        @select-position="isInEditMode && (position = $event)"
      />
      <div v-if="!isInEditMode" class="flex justify-end">
        <button
          :disabled="isInEditMode"
          class="link flex items-center gap-2 text-primary"
          @click.prevent="isInEditMode = true"
        >
          <span>{{ t('editDamage') }}</span
          ><PencilIcon class="size-5" />
        </button>
      </div>
      <div v-if="isInEditMode" class="flex justify-end gap-2">
        <CVButton variant="success" :is-loading="updatingDamage" @click="saveDamage">{{
          t('save')
        }}</CVButton>
        <CVButton variant="warning" :disabled="updatingDamage" @click="cancelEdit">{{
          t('cancel')
        }}</CVButton>
      </div>
    </div>
    <div class="col-span-2 w-full">
      <div v-if="damage?.videoFileUrl" class="mb-2 flex gap-2">
        <a
          href="#"
          :class="{ 'text-link': showVideo, 'text-gray-500': !showVideo }"
          @click.prevent="showVideo = false"
          >{{ t('picture') }}</a
        >
        <div class="text-primary">|</div>
        <a
          href="#"
          :class="{ 'text-link': !showVideo, 'text-gray-500': showVideo }"
          @click.prevent="showVideo = true"
          >{{ t('video') }}</a
        >
      </div>
      <img v-show="!showVideo" :src="damage.imageFileUrl" class="w-full" />
      <video v-if="damage?.videoFileUrl" v-show="showVideo" class="w-full" controls>
        <source :src="damage?.videoFileUrl" />
      </video>
    </div>
  </div>
</template>

<script lang="ts" setup>
import LabeledText from '@/components/LabeledText.vue';
import type { Damage, Position } from '@/entities/damage.entity';
import { useFormattedDate } from '@/hooks/use-formatted-date';
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import MarkerContainer from './components/MarkerContainer.vue';
import { DamageStatus } from '@/entities/damage-status.enum';
import { useUpdateDamage } from '@/queries/use-damages';
import { Alert } from '@/utils/alert';
import Input from '@/components/Input.vue';
import CVButton from '@/components/buttons/CVButton.vue';
import { PencilIcon } from '@heroicons/vue/24/outline';

const props = defineProps<{
  damage: Damage;
}>();

const { t } = useI18n();

const description = ref(props.damage.description);
const position = ref<Position>({
  positionX: props.damage.positionX,
  positionY: props.damage.positionY,
});

const isInEditMode = ref(false);

const { mutateAsync: updateDamage, isPending: updatingDamage } = useUpdateDamage();
const showVideo = ref(false);

watch(
  () => props.damage,
  () => {
    showVideo.value = false;
  },
);

const createdDate = useFormattedDate(computed(() => props.damage.createdDate));

const repairedDate = useFormattedDate(computed(() => props.damage.repairedDate));

const setDamageStatusToRepaired = async () => {
  const result = await Alert.fire({
    title: t('areYouSure'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('yes'),
    cancelButtonText: t('no'),
  });

  if (!result.isConfirmed) return;

  await updateDamage({
    id: props.damage?.id,
    damage: {
      status: DamageStatus.REPAIRED,
    },
  });
};

const saveDamage = async () => {
  await updateDamage({
    id: props.damage?.id,
    damage: {
      description: description.value,
      positionX: position.value.positionX,
      positionY: position.value.positionY,
    },
  });
  isInEditMode.value = false;
};

const cancelEdit = async () => {
  isInEditMode.value = false;
  position.value = {
    positionX: props.damage.positionX,
    positionY: props.damage.positionY,
  };
  description.value = props.damage.description;
};
</script>

<i18n lang="json">
{
  "en": {
    "created": "Created",
    "description": "Description",
    "repair": "Repair",
    "confirmRepair": "Confirm repair",
    "repairedAt": "Repaired at",
    "areYouSure": "Are you sure?",
    "picture": "Picture",
    "video": "Video",
    "editDamage": "Edit damage"
  },
  "de": {
    "created": "Erstellt",
    "description": "Beschreibung",
    "repair": "Reparatur",
    "confirmRepair": "Reparatur bestätigen",
    "repairedAt": "Repariert am",
    "areYouSure": "Bist du sicher?",
    "picture": "Foto",
    "video": "Video",
    "editDamage": "Schaden bearbeiten"
  }
}
</i18n>
