<template>
  <div
    ref="markerContainerRef"
    class="relative select-none overflow-hidden border border-primary/50"
    data-testid="marker-container"
    @click="setPosition"
  >
    <img ref="imageRef" src="@/assets/damage-map.jpg" class="w-full" crossorigin="anonymous" />
    <div v-if="markerPositions?.length">
      <span
        v-for="(pos, index) in markerPositions"
        :key="index"
        class="absolute flex h-7 w-7 translate-x-[-50%] translate-y-[-50%] transform items-center justify-center rounded-full bg-red-500 text-lg font-medium text-white"
        :style="{
          top: `${pos.positionY ?? 0}%`,
          left: `${pos.positionX ?? 0}%`,
        }"
      >
        {{ !hidePositionNumbers ? index + 1 : '' }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Position } from '@/entities/damage.entity';
import { ref } from 'vue';

defineProps<{
  markerPositions?: Position[];
  hidePositionNumbers?: boolean;
}>();

const emits = defineEmits<{
  (e: 'selectPosition', position: Position): void;
}>();

const markerContainerRef = ref<HTMLElement>();

const setPosition = (e: MouseEvent) => {
  const width = markerContainerRef.value?.offsetWidth;
  const height = markerContainerRef.value?.offsetHeight;

  if (!width || !height) return;

  const positionX = Math.round((e.offsetX / width) * 10000) / 100;
  const positionY = Math.round((e.offsetY / height) * 10000) / 100;
  emits('selectPosition', { positionX, positionY });
};
</script>
