import { createAliasResolver } from '@casl/ability';

type CRUD = 'manage' | READ | 'create' | 'update' | 'delete';
type READ = 'read' | 'view' | 'query' | 'get' | 'list';
export type RosAbilities =
  | ['manage', 'all']
  | ['ros', 'Dashboard' | 'VehiclePool' | 'Bookings' | 'CreateBooking']
  | [
      CRUD,
      'Car' | 'VehicleType' | 'User' | 'Invoice' | 'PaymentTerminal' | 'Coupon' | 'PricingModifier',
    ]
  | [CRUD, 'News']
  | [CRUD, 'InsuranceCase']
  | [CRUD, 'Station']
  | [READ, 'AuditLog']
  | [CRUD | 'handle' | 'send-mails', 'Booking']
  | ['reload', 'FeatureFlags'];

export const aliasResolver = createAliasResolver({
  read: ['view', 'query'],
  query: ['get', 'list'],
});
