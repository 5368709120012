<template>
  <h2>{{ t('extras') }}</h2>
  <div class="mt-5">
    <div class="relative">
      <div class="flex w-full flex-col space-y-2">
        <div
          v-for="[extra, i] in availableExtras
            .map((extra, i) => [extra, i] as const)
            .filter(([extra]) => visibleExtras[extra.type])"
          :key="i"
          class="grid w-full grid-cols-2"
        >
          <div v-if="!immutable" class="flex justify-start">
            <Select
              v-model="bookedExtrasSelection[extra.type]"
              :disabled="disabled"
              :placeholder="t(`vehicleExtras.${extra.type}`)"
              :options="getOptionLabels(extra)"
              class="w-full"
              @change="handleExtrasChange"
            />
            <Information v-if="extra.type === VehicleExtraType.CROSSBORDER"
              ><template #modal>{{ t('crossBorderInfo') }}</template></Information
            >
            <Information v-if="extra.type === VehicleExtraType.EXTRA_KM"
              ><template #modal>{{
                t('extrasKmInfo', {
                  price: formatCurrency(booking.priceCalculation.pricePerExtraKm),
                })
              }}</template></Information
            >
          </div>
          <div
            v-if="bookedOptionInfos[i]"
            class="flex self-center font-medium text-error"
            :class="{
              'text-success': bookedOptionInfos[i].isBookedOption,
              'ml-4': !immutable,
            }"
          >
            {{ bookedOptionInfos[i].info }}
            {{ bookedOptionInfos[i].isBookedOption ? '✓' : '✕' }}
            <Information v-if="extra.type === VehicleExtraType.CROSSBORDER && immutable"
              ><template #modal>{{ t('crossBorderInfo') }}</template></Information
            >
          </div>
        </div>
        <div class="flex">
          <Dropdown
            position="left"
            :buttons="
              availableExtras
                .filter((extra) => !visibleExtras[extra.type])
                .map((extra) => ({
                  title: t(`vehicleExtras.${extra.type}`),
                  onClick: () => {
                    visibleExtras[extra.type] = true;
                  },
                }))
            "
          >
            <template #trigger>
              <button class="btn btn-ghost btn-sm" @click.prevent="">
                <span name="plus" class="mr-1">+</span> {{ t('addExtra') }}
              </button>
            </template>
          </Dropdown>
        </div>

        <p v-if="hasChanged && !recalculatingPrice" class="font-medium">
          {{ t('newPrice') }}:
          <span v-currency="priceCalculation?.totalPrice"></span>
        </p>

        <p v-if="hasChanged && recalculatingPrice" class="font-medium">
          {{ t('recalculatingPrice') }}
        </p>

        <div v-if="hasChanged" class="col-span-3 mt-2 flex">
          <CVButton
            variant="success"
            :disabled="recalculatingPrice"
            :is-loading="isSaving"
            class="mr-2"
            @click.prevent="saveExtrasChange"
          >
            {{ t('save') }}
          </CVButton>
          <CVButton
            variant="warning"
            :disabled="isSaving || recalculatingPrice"
            class="mr-2"
            @click.prevent="cancelExtrasChange"
          >
            {{ t('cancel') }}
          </CVButton>
        </div>
      </div>
      <div
        v-if="isSaving || recalculatingPrice"
        class="absolute -inset-2 flex items-center justify-center bg-black/20"
      >
        <Spinner />
      </div>
    </div>

    <div v-if="showCheckbox" class="form-control col-span-3">
      <label class="label cursor-pointer justify-start">
        <input v-model="extrasConfirmed" type="checkbox" class="checkbox mr-2 bg-white" />
        <span class="label-text">{{ t('doubleCheckExtras') }}</span>
      </label>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import type { VehicleExtra } from '@/entities/vehicle-extra.entity';
import type { VehicleExtraOption } from '@/entities/vehicle-extra-option.entity';
import { useI18n } from 'vue-i18n';
import { type Booking, type UpdateBookingDto } from '@/entities/bookings/booking.entity';
import Select from '@/components/Select.vue';
import type { Pricing } from '@/entities/pricing.entity';
import { VehicleExtraType } from '@/entities/vehicle-extra-type.enum';
import { formatCurrency } from '@/utils/format-numbers';
import Information from '../Information.vue';
import type { RecalculatePriceParams } from '@/hooks/use-recalculate-price';
import Dropdown from '@/components/Dropdown.vue';

type VehicleExtraOptionForLabel = VehicleExtraOption & {
  optionValue: string;
  labelSuffix?: string;
};

const props = defineProps<{
  extrasConfirmed: boolean;
  availableExtras: VehicleExtra[];
  disabled?: boolean;
  showCheckbox?: boolean;
  priceCalculation: Pricing;
  recalculatingPrice?: boolean;
  isSaving?: boolean;
  booking: Booking;
  immutable?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:extrasConfirmed', value: boolean): void;
  (e: 'update:priceCalculation', value: Pricing): void;
  (e: 'hasChanged', value: boolean): void;
  (e: 'recalculate-price', value: RecalculatePriceParams): void;
  (
    e: 'update-booking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished: () => void,
  ): void;
}>();

const { t } = useI18n();

function getInitialSelectedExtras() {
  const res: Record<string, string> = {};
  for (const extra of props.availableExtras) {
    const optionId = extra.options[0].id;
    if (
      props.booking.priceCalculation.customBookedExtras?.some(
        (customExtra) => customExtra.customVehicleExtraType === extra.type,
      )
    ) {
      res[extra.type] = 'custom';
    } else {
      res[extra.type] = extra.id + ';' + optionId;
    }
  }
  for (let e of props.priceCalculation.bookedExtras) {
    res[e.vehicleExtra.type] = 'unchanged';
  }
  return res;
}

function getVisibleExtras() {
  const res: Record<string, boolean> = {};
  for (const extra of props.availableExtras) {
    res[extra.type] = !!props.booking.priceCalculation.customBookedExtras?.some(
      (customExtra) => customExtra.customVehicleExtraType === extra.type,
    );
  }
  for (let e of props.priceCalculation.bookedExtras) {
    res[e.vehicleExtra.type] =
      (e.calculatedPrice ?? 0) > 0 ||
      ![
        VehicleExtraType.BABY_SEAT,
        VehicleExtraType.CHILD_SEAT_0,
        VehicleExtraType.CHILD_SEAT_1,
        VehicleExtraType.BOOSTER_SEAT,
        VehicleExtraType.YOUNG_DRIVER,
      ].includes(e.vehicleExtra.type);
  }
  return res;
}

const bookedExtrasSelection = ref(getInitialSelectedExtras());
const visibleExtras = ref(getVisibleExtras());
const extrasConfirmed = ref(props.extrasConfirmed);
const hasChanged = ref(false);
const availableExtras = computed(() =>
  [...props.availableExtras].sort((extraA, extraB) => (extraA.type > extraB.type ? 1 : -1)),
);

watch(
  () => props.booking.priceCalculation,
  () => {
    bookedExtrasSelection.value = getInitialSelectedExtras();
    visibleExtras.value = getVisibleExtras();
  },
);

const getOptionLabels = (extra: VehicleExtra) => {
  const optionLabels: VehicleExtraOptionForLabel[] = extra.options.map((option) => ({
    ...option,
    optionValue: `${extra.id};${option.id}`,
  }));

  const labels = [];

  const bookedExtra = props.booking.priceCalculation.bookedExtras.find(
    (bookedExtra) => bookedExtra.vehicleExtra.type === extra.type,
  );

  const customExtra = props.booking.priceCalculation.customBookedExtras?.find(
    (customExtra) => customExtra.customVehicleExtraType === extra.type,
  );

  if (customExtra) {
    labels.unshift({
      label: t(`customExtra`),
      value: 'custom',
    });
  }
  if (bookedExtra) {
    optionLabels.unshift({
      ...bookedExtra.vehicleExtraOptionSnapshot,
      optionValue: `unchanged`,
      labelSuffix:
        bookedExtra.vehicleExtra.id !== extra.id ? t('fromFormerVehicle') : t('currentSelection'),
    });
  }

  optionLabels.forEach((option: VehicleExtraOptionForLabel) => {
    const price = option.calculatedPrice ?? 0;

    const notPerDay = extra.type === VehicleExtraType.EXTRA_KM;

    const priceLabel = notPerDay
      ? `${formatCurrency(price)}`
      : `${formatCurrency(
          price / (props.priceCalculation.days ? props.priceCalculation.days : 1),
        )}/${t('day')}`;

    const name = `${
      option.value === 'true'
        ? ''
        : option.value === 'false'
          ? t('noOption')
          : option.name.length > 0
            ? option.name
            : option.value
    }`;
    const label = `${name} ${t(`vehicleExtras.${extra.type}`)} - ${priceLabel}${
      option.labelSuffix ? ` | (${option.labelSuffix})` : ''
    }`;
    labels.push({
      label,
      value: option.optionValue,
    });
  });
  return labels;
};

const bookedOptionInfos = computed(() =>
  availableExtras.value.map((extra) => {
    const bookedExtra = props.booking.priceCalculation.bookedExtras.find(
      (bookedExtra) => bookedExtra.vehicleExtra.type === extra.type,
    );
    let option = bookedExtra?.vehicleExtraOptionSnapshot;

    if (!option) {
      option = extra.options[0];
    }

    const price = option.calculatedPrice ?? bookedExtra?.calculatedPrice ?? 0;

    const isBookedOption = bookedExtra?.isDefaultOptionSelected === false;

    let info = '';
    if (option) {
      const name = `${
        option.value === 'true'
          ? ''
          : option.value === 'false'
            ? t('noOption')
            : option.name.length > 0
              ? option.name
              : option.value
      }`;
      info = `${name} ${t(`vehicleExtras.${extra.type}`)} | ${formatCurrency(price)}`;
    }
    return {
      info,
      isBookedOption,
    };
  }),
);

const handleExtrasChange = () => {
  const changedBookedExtraTypes = Object.entries(bookedExtrasSelection.value)
    .filter(([_, optionValue]) => optionValue !== 'unchanged' && optionValue !== 'custom')
    .map(([extraType, optionValue]) => {
      const parts = optionValue.split(';');
      return [extraType, { vehicleExtraId: parts[0], vehicleExtraOptionId: parts[1] }];
    });
  hasChanged.value = changedBookedExtraTypes.length > 0;
  if (!hasChanged.value) {
    emit('update:priceCalculation', props.booking.priceCalculation);
    return;
  }
  emit('recalculate-price', {
    changedBookedExtraTypes: Object.fromEntries(changedBookedExtraTypes),
    showAlert: false,
    nullExtrasOnConfirm: false,
  });
};

const cancelExtrasChange = () => {
  hasChanged.value = false;
  emit('update:priceCalculation', props.booking.priceCalculation);
};

const saveExtrasChange = async () => {
  const updateBookingValues: UpdateBookingDto = {
    priceCalculationId: props.priceCalculation.id,
  };
  emit(
    'update-booking',
    {
      id: props.booking.id,
      booking: updateBookingValues,
    },
    () => (hasChanged.value = false),
  );
};

watch(extrasConfirmed, (newValue) => emit('update:extrasConfirmed', newValue));
watch(hasChanged, (newValue) => emit('hasChanged', newValue));
</script>

<i18n lang="json">
{
  "en": {
    "extras": "Extras",
    "doubleCheckExtras": "Was informed about deductible, cross border, additional drivers & extra kilometers",
    "noOption": "No",
    "newPrice": "New Price",
    "recalculatingPrice": "Recalculating Price...",
    "fromFormerVehicle": "From former Vehicle",
    "currentSelection": "Current Selection",
    "day": "Day",
    "extrasKmInfo": "Each additional km will be charged with {price}",
    "customExtra": "Custom Extra",
    "addExtra": "Add Extra"
  },
  "de": {
    "extras": "Extras",
    "doubleCheckExtras": "Wurde über Selbstbeteiligung, Auslandsfahrt, Zusatzfahrer & Extrakilometer aufgeklärt",
    "noOption": "Kein",
    "newPrice": "Neuer Preis",
    "recalculatingPrice": "Preis wird neu berechnet...",
    "fromFormerVehicle": "Von vorherigem Fahrzeug",
    "currentSelection": "Aktuelle Auswahl",
    "day": "Tag",
    "extrasKmInfo": "Jeder zusätzliche Km wird mit {price} berechnet",
    "customExtra": "Individuelles Extra",
    "addExtra": "Extra hinzufügen"
  }
}
</i18n>
