import type { Coupon } from '@/entities/coupons/coupon.entity';
import type { PaginatedResponse } from '@/entities/pagination/paginated-response';
import { Service } from './http-service';

export interface CreateCouponDto {
  remainingValue: number;
  originalValue: number;
  isActive: boolean;
  customerData?: {
    firstName?: string;
    lastName?: string;
    phone?: string;
    street?: string;
    city?: string;
    zip?: string;
    country?: string;
    email?: string;
  };
}

export interface UpdateCouponDto extends Partial<CreateCouponDto> {
  validTo?: Date;
}

export interface FindAllCouponsQueryDto {
  page?: number;
  limit?: number;
  search?: string;
  isActive?: boolean;
}

class CouponsService extends Service<Coupon, CreateCouponDto, UpdateCouponDto> {
  async getAllWithQuery(params: FindAllCouponsQueryDto = {}) {
    const res = await this.httpClient.get<PaginatedResponse<Coupon>>(`${this.endpoint}`, {
      params,
    });
    return res.data;
  }

  async downloadPdf(id: string): Promise<Blob> {
    const response = await this.httpClient.get(`${this.endpoint}/${id}/pdf`, {
      responseType: 'blob',
    });
    return response.data;
  }

  async update(id: string, data: UpdateCouponDto): Promise<Coupon> {
    const response = await this.httpClient.patch<Coupon>(`${this.endpoint}/${id}`, data);
    return response.data;
  }
}

export const couponsService = new CouponsService('/coupons');
