<template>
  <button
    class="flex items-center gap-4 rounded-lg p-2 text-left text-orange-900/80"
    :class="{
      'bg-primary/10 hover:bg-primary/20': !hasError,
      'bg-error/20 hover:bg-error/30': hasError,
    }"
  >
    <div class="handle cursor-grab">
      <Icon name="bars-3" class="text-black/20" />
    </div>
    <div class="flex basis-1/4 items-center gap-2">
      <span class="text-xs text-orange-900/60">{{ t('name') }}:</span>{{ template.name }}
    </div>
    <div class="flex basis-3/4 items-center gap-2">
      <span class="text-xs text-orange-900/60">{{ t('conditions') }}:</span>
      {{
        Object.entries(template.conditions)
          .filter(([_, value]) => value)
          .map(([key]) => t('pricingModifierConditions.' + key))
          .join(', ')
      }}
    </div>
    <button
      class="flex size-6 items-center justify-center rounded-md hover:bg-white"
      @click.stop="$emit('delete')"
    >
      <XMarkIcon class="size-4 text-error" />
    </button>
  </button>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/solid';
import type { PricingModifierTemplateDto } from '@carvia/ros-client-types';
import { useI18n } from 'vue-i18n';

defineProps<{
  template: PricingModifierTemplateDto;
  hasError?: boolean;
}>();

defineEmits<{
  (e: 'delete'): void;
}>();

const { t } = useI18n();
</script>

<i18n lang="json">
{
  "en": {
    "name": "Name",
    "conditions": "Conditions"
  },
  "de": {
    "name": "Name",
    "conditions": "Bedingungen"
  }
}
</i18n>
