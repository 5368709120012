import type { PaginatedResponse } from '@/entities/pagination/paginated-response';
import type { InfiniteData, QueryClient, QueryKey } from '@tanstack/vue-query';

export type QueriesDataType<T> = InfiniteData<PaginatedResponse<T>> | T[] | T;

export const addItemToQueryData = <T>(queryKey: QueryKey, result: T, queryClient: QueryClient) => {
  const old = queryClient.getQueryData<InfiniteData<PaginatedResponse<T>>>(queryKey);
  if (!old) return;
  const updatedData = {
    pageParams: old.pageParams,
    pages: old.pages.map((page, i, pages) =>
      i === pages.length - 1 ? { ...page, items: [...page.items, result] } : page,
    ),
  };
  queryClient.setQueryData(queryKey, () => updatedData);
};

export const addItemToQueryDataUnpaginated = <T>(
  queryKey: QueryKey,
  result: T,
  queryClient: QueryClient,
) => {
  const oldData = queryClient.getQueryData<T[]>(queryKey);
  if (!oldData) return;
  const updatedData = oldData.concat([result]);
  queryClient.setQueryData(queryKey, () => updatedData);
};

export const updateItemOnQueryData = <T extends { id: string }>(
  queryKey: QueryKey,
  result: T,
  queryClient: QueryClient,
) => {
  const old = queryClient.getQueryData<InfiniteData<PaginatedResponse<T>>>(queryKey);
  if (!old) return;
  const updatedData = {
    pageParams: old.pageParams,
    pages: old.pages.map((page) => ({
      ...page,
      items: page.items.map((item) => (item.id === result.id ? result : item)),
    })),
  };
  queryClient.setQueryData(queryKey, () => updatedData);
};

export const removeItemFromQueryData = (
  queryKey: QueryKey,
  removeId: string | undefined,
  queryClient: QueryClient,
) => {
  const old = queryClient.getQueryData<InfiniteData<PaginatedResponse<{ id: string }>>>(queryKey);
  if (!old) return;
  const updatedData = {
    pageParams: old.pageParams,
    pages: old.pages.map((page) => ({
      ...page,
      items: page.items.filter((item) => item.id !== removeId),
    })),
  };
  queryClient.setQueryData(queryKey, () => updatedData);
};

export const updateItemOnQueriesData = <T extends { id: string }>(
  queryKey: QueryKey,
  result: T,
  queryClient: QueryClient,
) => {
  const oldQueryData = queryClient.getQueriesData<QueriesDataType<T>>({
    queryKey,
  });
  if (!oldQueryData) return;
  oldQueryData.forEach((queryData) => {
    const [key, oldData] = queryData;
    if (!oldData) return;
    if ('pages' in oldData) {
      const updatedData = {
        pageParams: oldData.pageParams,
        pages: oldData.pages.map((page) => ({
          ...page,
          items: page.items.map((item) => (item.id === result.id ? result : item)),
        })),
      };
      queryClient.setQueryData(key, () => updatedData);
    } else if (Array.isArray(oldData)) {
      queryClient.setQueryData(key, () =>
        oldData.map((item) => (item.id === result.id ? result : item)),
      );
    } else if (oldData && 'id' in oldData && result.id === oldData.id) {
      queryClient.setQueryData(key, () => result);
    }
  });
};

export const addItemToQueriesData = <T extends { id: string | number }>(
  queryKey: QueryKey,
  result: T,
  queryClient: QueryClient,
) => {
  const oldQueryData = queryClient.getQueriesData<QueriesDataType<T>>({
    queryKey,
  });
  if (!oldQueryData) return;
  oldQueryData.forEach((queryData) => {
    const [key, oldData] = queryData;
    if (!oldData) return;
    if ('pages' in oldData) {
      const updatedData = {
        pageParams: oldData.pageParams,
        pages: oldData.pages.map((page, i, pages) =>
          i === pages.length - 1 ? { ...page, items: [...page.items, result] } : page,
        ),
      };
      queryClient.setQueryData(key, () => updatedData);
    } else if (Array.isArray(oldData)) {
      oldData.push(result);
      queryClient.setQueryData(key, () => oldData);
    }
  });
};

export const removeItemFromQueriesData = <T extends { id: string }>(
  queryKey: QueryKey,
  removeId: string | undefined,
  queryClient: QueryClient,
) => {
  const oldQueryData = queryClient.getQueriesData<QueriesDataType<T>>({
    queryKey,
  });
  if (!oldQueryData) return;
  oldQueryData.forEach((queryData) => {
    const [key, oldData] = queryData;
    if (!oldData) return;
    if ('pages' in oldData) {
      const updatedData = {
        pageParams: oldData.pageParams,
        pages: oldData.pages.map((page) => ({
          ...page,
          items: page.items.filter((item) => item.id !== removeId),
        })),
      };
      queryClient.setQueryData(key, () => updatedData);
    } else if (Array.isArray(oldData)) {
      const updatedData = oldData.filter((item) => item.id !== removeId);
      queryClient.setQueryData(key, () => updatedData);
    }
  });
};
