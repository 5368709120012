import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { type MaybeRefOrGetter, toRef, toValue } from 'vue';
import type { MutationDefaultsContext } from './query-client';
import { addItemToQueriesData } from '@/utils/update-query-data';
import { pricingModifiersService } from '@/api/pricing-modifiers.service.ts';
import type { CreatePricingModifiersDto, PricingModifiers } from '@carvia/ros-client-types';

const QUERY_KEY = 'pricing-modifiers';

export const useLatestPricingModifiers = () => {
  return useQuery({
    queryKey: [QUERY_KEY, 'latest'],
    queryFn: () => pricingModifiersService.getLatest(),
  });
};

export const usePricingModifiers = (
  id: MaybeRefOrGetter<string>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(id)],
    queryFn: () => pricingModifiersService.getOne(toValue(id)),
    enabled: toValue(enabled),
  });
};

export const useCreatePricingModifiers = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (pricingModifiers: CreatePricingModifiersDto) =>
      pricingModifiersService.create(pricingModifiers),
    onMutate: (): MutationDefaultsContext => ({
      successMessage: 'Pricing modifiers saved',
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      addItemToQueriesData<PricingModifiers>([QUERY_KEY], result, queryClient);
    },
  });
};
