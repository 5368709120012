import type { PricingModifierTemplateDto } from '@carvia/ros-client-types';
import { ref } from 'vue';
import * as yup from 'yup';

const getTemplate = (t: any) => {
  return t.options.context?.templates.find(
    (template: PricingModifierTemplateDto) =>
      template.id === t.options.context?.modifiers[t.options.index].templateId,
  );
};

export const pricingModifiersSchema = ref(
  yup.object({
    templates: yup.array().of(
      yup.object({
        name: yup.string().required(),
        conditions: yup.object().test((value: any) => {
          return Object.values(value).some((v) => v);
        }),
      }),
    ),
    modifiers: yup.array().of(
      yup.object({
        factor: yup.number().required(),
        conditions: yup.object({
          allowedDiscountTimePeriod: yup
            .number()
            .nullable()
            .test('is-required', 'Required', function (value) {
              const template = getTemplate(this);
              return template?.conditions.allowedDiscountTimePeriod
                ? typeof value === 'number'
                : true;
            }),
          categories: yup
            .array()
            .nullable()
            .test('is-required', 'Required', function (value) {
              const template = getTemplate(this);
              return template?.conditions.categories ? !!value?.length : true;
            }),
          fromDayDuration: yup
            .number()
            .nullable()
            .test('is-required', 'Required', function (value) {
              const template = getTemplate(this);
              return template?.conditions.fromDayDuration ? typeof value === 'number' : true;
            }),
          makes: yup
            .array()
            .nullable()
            .test('is-required', 'Required', function (value) {
              const template = getTemplate(this);
              return template?.conditions.makes ? !!value?.length : true;
            }),
          fromStationLoad: yup
            .number()
            .nullable()
            .test('is-required', 'Required', function (value) {
              const template = getTemplate(this);
              return template?.conditions.fromStationLoad ? typeof value === 'number' : true;
            }),
          stations: yup
            .array()
            .nullable()
            .test('is-required', 'Required', function (value) {
              const template = getTemplate(this);
              return template?.conditions.stations ? !!value?.length : true;
            }),
          timePeriod: yup
            .object({
              fromMonth: yup.number().required(),
              toMonth: yup.number().required(),
              isYearly: yup.boolean(),
              fromYear: yup.number().when('isYearly', {
                is: (val: boolean) => val,
                then: (schema) => schema,
                otherwise: (schema) => schema.required(),
              }),
            })
            .default(null)
            .nullable()
            .test('is-required', 'Required', function (value) {
              const template = getTemplate(this);
              return template?.conditions.timePeriod ? !!value : true;
            }),
          weekDays: yup
            .array()
            .nullable()
            .test('is-required', 'Required', function (value) {
              const template = getTemplate(this);
              return template?.conditions.weekDays ? !!value?.length : true;
            }),
        }),
      }),
    ),
  }),
);
