<template>
  <div v-if="!changingFilter">
    <div class="grid grid-cols-2 gap-7">
      <VehiclesListItem
        v-for="offer of offers"
        :key="offer.car.id"
        :offer="offer"
        :start-date="startDate"
        @click="() => $emit('selectOffer', offer)"
      />
    </div>

    <div v-if="isFetchingNextPage" class="mt-8 flex justify-center">
      <Spinner />
    </div>

    <EntityListExceptions
      :status="status"
      :error="error"
      :items-count="offers.length"
      :no-items-text="t('noCarsFound')"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, unref, watch } from 'vue';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import EntityListExceptions from '@/components/EntityListExceptions.vue';
import { useI18n } from 'vue-i18n';
import VehiclesListItem from './VehiclesListItem.vue';
import { useScrollPosition } from '@/hooks/use-scroll-position';
import { CUSTOM_LOCATIONS } from '@/constants/custom-locations.constant';
import type { BookingLocation } from '@/entities/bookings/booking.entity';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import { useOffers } from '@/queries/use-offers';
import { GetOffersSort, type Offer } from '@carvia/ros-client-types';
import type { CreateBookingProps } from '@/views/bookings/booking-create/components/use-create-booking-props';

const props = defineProps<{
  createBookingProps: CreateBookingProps;
  changingFilter?: boolean;
  onlyClosestToDeliveryLocation?: boolean;
}>();

defineEmits<{
  (event: 'selectOffer', offer: Offer): void;
}>();

const { t } = useI18n();
const {
  station,
  pickupLocation,
  dropoffLocation,
  startDate,
  endDate,
  categoryFilter,
  makeFilter,
  insuranceCase,
} = props.createBookingProps;

const { scrollPositionBottom } = useScrollPosition();

const {
  data: offersData,
  status,
  error,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
} = useOffers(
  computed(() => ({
    stationIds:
      station.value !== CUSTOM_LOCATIONS && station.value ? [station.value.id] : undefined,
    transferDeliveryLocation: locationToCoordinates(pickupLocation.value),
    transferReturnLocation: locationToCoordinates(dropoffLocation.value),
    startDate: startDate.value,
    endDate: endDate.value,
    categories: categoryFilter.value ? [categoryFilter.value] : undefined,
    makeIds: makeFilter.value ? [makeFilter.value] : undefined,
    insuranceCaseId: insuranceCase.value?.id,
    isAvailable: true,
    isGhostCar: false,
    ignoreOpeningHoursAndHolidays: true,
    onlyClosestToDeliveryLocation: props.onlyClosestToDeliveryLocation,
    reduceCarsToOnePerVehicleType: false,
    limit: 20,
    sort: [GetOffersSort.TOTAL_PRICE],
  })),
);

function locationToCoordinates(location: BookingLocation | null): [number, number] | undefined {
  if (!location) {
    return undefined;
  }
  if (location.locationType === BookingLocationType.STATION) {
    return location.station?.location?.coordinates;
  }
  return location.location?.coordinates
    ? [location.location?.coordinates?.longitude, location.location?.coordinates?.latitude]
    : undefined;
}

const offers = useFlattenPaginatedData(offersData);

watch(scrollPositionBottom, (distance) => {
  if (distance < 400 && unref(hasNextPage) && !unref(isFetchingNextPage)) {
    fetchNextPage();
  }
});
</script>

<i18n lang="json">
{
  "en": {
    "noCarsFound": "No cars found"
  },
  "de": {
    "noCarsFound": "Keine Autos gefunden"
  }
}
</i18n>
