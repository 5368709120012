import { i18n } from '@/i18n.ts';
import { toValue } from 'vue';

export const formatCurrency = (value: number) => {
  const formatter = new Intl.NumberFormat(toValue(i18n.global.locale), {
    style: 'currency',
    currency: 'EUR',
  });

  return formatter.format(value);
};

export const formatFloat = (value: number | undefined | null, decimals = 2) => {
  const formatter = new Intl.NumberFormat(toValue(i18n.global.locale), {
    style: 'decimal',
    maximumFractionDigits: decimals,
  });

  return value ? formatter.format(value) : '0';
};

export const addLeadingZeros = (value: number, minimumDigits: number) => {
  const formatter = new Intl.NumberFormat(toValue(i18n.global.locale), {
    minimumIntegerDigits: minimumDigits,
  });

  return formatter.format(value);
};
