<template>
  <HeaderBar />

  <section class="carvia-container">
    <PricingModifiersForm
      v-if="initialPricingModifiersDto"
      :initial-pricing-modifiers-dto="initialPricingModifiersDto"
      :is-creating="isCreating"
      @on-submit="onSubmit"
    />
    <div v-else class="flex justify-center"><Spinner /></div>
  </section>
</template>

<script lang="ts" setup>
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useI18n } from 'vue-i18n';
import { useUiStore } from '@/stores/ui.store';
import {
  useCreatePricingModifiers,
  useLatestPricingModifiers,
} from '@/queries/use-pricing-modifiers.ts';
import { type CreatePricingModifiersDto } from '@carvia/ros-client-types';
import { computed } from 'vue';
import PricingModifiersForm from './PricingModifiersForm.vue';

const { t } = useI18n();
const uiStore = useUiStore();

const { data, isFetching } = useLatestPricingModifiers();
const { mutateAsync: create, isPending: isCreating } = useCreatePricingModifiers();

const initialPricingModifiersDto = computed<CreatePricingModifiersDto | undefined>(() => {
  if (isFetching) {
    return data.value;
  } else {
    return data.value ?? { templates: [], modifiers: [] };
  }
});

uiStore.setHeaderTitle(t('pricingModifiers'));

const onSubmit = async (formValues: CreatePricingModifiersDto) => {
  await create(formValues);
};
</script>

<i18n lang="json">
{
  "en": {
    "pricingModifiers": "Pricing modifiers"
  },
  "de": {
    "pricingModifiers": "Preismodifikatoren"
  }
}
</i18n>
