<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="group relative flex" :class="{ 'text-gray-400': !hasButtons }">
    <div @mouseenter="deactivateListHover = false">
      <slot name="trigger"></slot>
    </div>
    <ul
      :class="[
        { 'group-hover:visible': hasButtons && !deactivateListHover },
        position === 'left' ? 'left-0' : 'right-0',
      ]"
    >
      <template v-if="buttons && buttons.length > 0">
        <li v-for="(button, i) in buttons" :key="i">
          <button @click.prevent.stop="onClick(button)">
            {{ button.title }}
          </button>
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

export interface DropdownItem {
  title: string;
  onClick?: () => void;
}

const props = defineProps<{
  buttons: DropdownItem[];
  position?: 'left' | 'right';
}>();

const deactivateListHover = ref(false);
const hasButtons = computed(() => props.buttons.length > 0);

const onClick = (button: DropdownItem) => {
  deactivateListHover.value = true;
  button.onClick?.();
};

const closeDropdown = () => {
  deactivateListHover.value = true;
};

defineExpose({ closeDropdown });
</script>

<style scoped>
ul {
  @apply invisible absolute top-9 z-50 w-52
      min-w-max space-y-1 rounded-box bg-base-100 p-2
      opacity-0 shadow transition-all ease-in
      group-hover:flex group-hover:flex-col group-hover:opacity-100;
}
li > button {
  @apply w-full p-2 text-left transition-colors duration-75 hover:bg-slate-200;
}
</style>
