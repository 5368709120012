<template>
  <HeaderBar />

  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
    <AuditLogButton entity-type="Coupon" :entity-id="route.params.id as string" class="ml-2" />
  </ButtonBackContainer>

  <section class="carvia-container">
    <div class="mb-6">
      <h1 class="text-2xl font-bold text-primary">{{ t('editCoupon') }}</h1>
    </div>

    <div v-if="isLoading" class="flex justify-center py-8">
      <Spinner />
    </div>

    <div v-else-if="data">
      <div class="mb-8 rounded-lg bg-gray-50 p-4">
        <div class="mb-1 text-sm text-gray-600">{{ t('couponCode') }}</div>
        <div class="font-mono text-2xl font-bold">{{ data.code }}</div>
      </div>

      <CouponForm :initial-data="data" :submit-label="t('save')" @submit="onSubmit">
        <template #actions>
          <div class="flex w-full justify-between">
            <CVButton
              variant="error"
              :loading="deleteMutation.isPending"
              @click.prevent="handleDelete"
            >
              {{ t('delete') }}
            </CVButton>
            <CVButton type="submit" :loading="updateMutation.isPending">
              {{ t('save') }}
            </CVButton>
          </div>
        </template>
      </CouponForm>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router';
import type { Coupon } from '@/entities/coupons/coupon.entity';
import CouponForm from '../components/CouponForm.vue';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import CVButton from '@/components/buttons/CVButton.vue';
import AuditLogButton from '@/components/audit-log/AuditLogButton.vue';
import Spinner from '@/components/icons/Spinner.vue';
import { useI18n } from 'vue-i18n';
import { Alert } from '@/utils/alert';
import { useCoupon, useUpdateCoupon, useDeleteCoupon } from '@/queries/use-coupons';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const { data, isLoading } = useCoupon(route.params.id as string);

const updateMutation = useUpdateCoupon();

const deleteMutation = useDeleteCoupon(() => {
  router.push({ name: 'couponList' });
});

const onSubmit = (data: Partial<Coupon>) => {
  updateMutation.mutate({
    id: route.params.id as string,
    update: {
      remainingValue: data.remainingValue,
      isActive: data.isActive,
      customerData: data.customerData,
      validTo: data.validTo != null ? new Date(data.validTo) : undefined,
    },
  });
};

const handleDelete = async () => {
  const { isConfirmed } = await Alert.fire({
    text: t('reallyDeleteCouponText'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('delete'),
    cancelButtonText: t('cancel'),
    confirmButtonColor: '#DC2626',
  });
  if (!isConfirmed) return;
  deleteMutation.mutate(route.params.id as string);
};
</script>

<i18n lang="json">
{
  "en": {
    "back": "Back",
    "editCoupon": "Edit Coupon",
    "save": "Save",
    "couponCode": "Coupon Code",
    "delete": "Delete",
    "cancel": "Cancel",
    "reallyDeleteCouponText": "Do you really want to delete this coupon?",
    "couponUpdated": "Coupon updated successfully",
    "couponDeleted": "Coupon deleted successfully"
  },
  "de": {
    "back": "Zurück",
    "editCoupon": "Gutschein bearbeiten",
    "save": "Speichern",
    "couponCode": "Gutschein-Code",
    "delete": "Löschen",
    "cancel": "Abbrechen",
    "reallyDeleteCouponText": "Möchtest du diesen Gutschein wirklich löschen?",
    "couponUpdated": "Gutschein erfolgreich aktualisiert",
    "couponDeleted": "Gutschein erfolgreich gelöscht"
  }
}
</i18n>
