<template>
  <div class="space-y-4">
    <template v-if="showTemplates">
      <div class="flex justify-between">
        <h2>{{ t('templates') }}</h2>
        <CVButton outline variant="error" @click="showTemplates = !showTemplates"
          >{{ t('hideTemplates') }} <XMarkIcon class="size-4"
        /></CVButton>
      </div>
      <VueDraggableNext
        :list="formValues.templates"
        class="flex flex-col gap-2"
        handle=".handle"
        @change="changeTemplatesOrder"
      >
        <TemplateListItem
          v-for="(template, index) in formValues.templates"
          :key="template.id"
          :template="template"
          :has-error="itemsWithErrors.templates.includes(index)"
          @click="showTemplateModal = index"
          @delete="deleteTemplate(index)"
        />
      </VueDraggableNext>
      <div class="flex justify-center">
        <button
          class="flex items-center justify-center rounded-lg bg-primary/10 px-4 py-2 text-orange-900/80 hover:bg-primary/20"
          @click="addTemplate"
        >
          <PlusIcon class="size-4" />{{ t('addTemplate') }}
        </button>
      </div>
      <Divider />
    </template>

    <div class="flex justify-between">
      <h2>{{ t('modifiers') }}</h2>
      <CVButton v-if="!showTemplates" outline @click="showTemplates = true"
        >{{ t('editTemplates') }} <PencilIcon class="size-4"
      /></CVButton>
    </div>
    <template v-for="template in formValues.templates" :key="template.id">
      <h3 class="text-lg font-medium">{{ template.name }}</h3>
      <VueDraggableNext
        :list="getModifiersOfTemplateId(template.id)"
        class="flex flex-col gap-2"
        handle=".handle"
        @change="changeModifiersOrder"
      >
        <ModifierListItem
          v-for="{ modifier, index } in getModifiersOfTemplateId(template.id)"
          :key="index"
          :modifier="modifier"
          :has-error="itemsWithErrors.modifiers.includes(index)"
          @click="showModifierModal = { index, template }"
          @delete="deleteModifier(index)"
        />
      </VueDraggableNext>
      <div class="flex justify-center">
        <button
          class="flex items-center justify-center rounded-lg bg-primary/10 px-4 py-2 text-orange-900/80 hover:bg-primary/20"
          @click="addModifier(template)"
        >
          <PlusIcon class="size-4" />{{ t('addModifier') }}
        </button>
      </div>
    </template>

    <Divider />

    <div class="flex justify-end">
      <CVButton
        :is-loading="isCreating"
        :disabled="!formHasChanged(unchangedValues, formValues)"
        @click="onSubmit"
        >{{ t('save') }}</CVButton
      >
    </div>

    <Modal :model-value="showTemplateModal !== null" @update:model-value="showTemplateModal = null">
      <TemplateItemForm :template-index="showTemplateModal!" @close="showTemplateModal = null" />
    </Modal>

    <vue-final-modal
      :model-value="showModifierModal !== null"
      class="flex items-center justify-center"
      content-class="modal-box w-11/12 max-w-5xl overflow-y-visible"
      content-transition="vfm-fade"
      overlay-transition="vfm-fade"
      click-to-close
      @update:model-value="showModifierModal = null"
    >
      <ModifierItemForm
        :modifier-index="showModifierModal!.index"
        :template="showModifierModal!.template"
        @close="showModifierModal = null"
      />
    </vue-final-modal>
  </div>
</template>

<script setup lang="ts">
import type {
  CreatePricingModifiersDto,
  PricingModifierDto,
  PricingModifierTemplateDto,
} from '@carvia/ros-client-types';
import { useFieldArray, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { computed, ref, watchEffect } from 'vue';
import { cloneDeep } from 'lodash';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form.ts';
import { formHasChanged } from '@/hooks/use-form-has-changed.ts';
import Divider from '@/components/Divider.vue';
import TemplateListItem from './TemplateListItem.vue';
import TemplateItemForm from './TemplateItemForm.vue';
import ModifierItemForm from './ModifierItemForm.vue';
import Modal from '@/components/Modal.vue';
import { PencilIcon, PlusIcon, XMarkIcon } from '@heroicons/vue/24/solid';
import { Alert } from '@/utils/alert.ts';
import ModifierListItem from '@/views/pricing-modifiers/ModifierListItem.vue';
import { pricingModifiersSchema } from '@/validation/pricing-modifiers.schema.ts';
import { VueDraggableNext } from 'vue-draggable-next';

const props = defineProps<{
  initialPricingModifiersDto: CreatePricingModifiersDto;
  isCreating: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSubmit', value: CreatePricingModifiersDto): void;
}>();

const { t } = useI18n();

const showTemplates = ref(false);
const showTemplateModal = ref<number | null>(null);
const showModifierModal = ref<{ index: number; template: PricingModifierTemplateDto } | null>(null);

const {
  handleSubmit,
  values: formValues,
  errors,
} = useForm({
  initialValues: props.initialPricingModifiersDto,
  keepValuesOnUnmount: true,
  validationSchema: pricingModifiersSchema,
});

const itemsWithErrors = computed(() => {
  const templateErrors = new Set<number>();
  const modifierErrors = new Set<number>();
  Object.keys(errors.value).forEach((key) => {
    const index = parseInt(key.match(/\[(\d+)\]/)?.[1] ?? '0');
    if (key.startsWith('templates')) {
      templateErrors.add(index);
    } else if (key.startsWith('modifiers')) {
      modifierErrors.add(index);
    }
  });
  return {
    templates: Array.from(templateErrors),
    modifiers: Array.from(modifierErrors),
  };
});

watchEffect(() => console.log(errors.value));

const templateFields = useFieldArray('templates');
const modifierFields = useFieldArray('modifiers');

const addTemplate = () => {
  const newId = Math.max(...formValues.templates.map((template) => template.id)) + 1;
  templateFields.push({ id: newId, name: '', conditions: {} });
  showTemplateModal.value = formValues.templates.length - 1;
};

const addModifier = (template: PricingModifierTemplateDto) => {
  modifierFields.push({ templateId: template.id, modifier: 1, conditions: {} });
  showModifierModal.value = { index: formValues.modifiers.length - 1, template };
};

const deleteTemplate = async (index: number) => {
  const { isConfirmed } = await Alert.fire({
    text: t('reallyDeleteTemplate'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('delete'),
    denyButtonText: t('cancel'),
  });
  if (isConfirmed) {
    modifierFields.replace(
      formValues.modifiers.filter((m) => m.templateId !== formValues.templates[index].id),
    );
    templateFields.remove(index);
  }
};

const deleteModifier = async (index: number) => {
  const { isConfirmed } = await Alert.fire({
    text: t('reallyDeleteModifier'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('delete'),
    denyButtonText: t('cancel'),
  });
  if (isConfirmed) {
    modifierFields.remove(index);
  }
};

const changeTemplatesOrder = ({ moved }: { moved: { newIndex: number; oldIndex: number } }) => {
  const { newIndex, oldIndex } = moved;
  templateFields.move(oldIndex, newIndex);
};

const changeModifiersOrder = ({
  moved,
}: {
  moved: { element: { index: number; modifier: PricingModifierDto }; newIndex: number };
}) => {
  const { element, newIndex } = moved;
  let nthOccurrence = 0;
  let actualNewIndex = 0;
  for (let i = 0; i < formValues.modifiers.length; i++) {
    if (formValues.modifiers[i].templateId === element.modifier.templateId) {
      if (nthOccurrence === newIndex) {
        actualNewIndex = i;
        break;
      }
      nthOccurrence++;
    }
  }
  modifierFields.move(element.index, actualNewIndex);
};

const getModifiersOfTemplateId = (id: number) => {
  return formValues.modifiers
    .map((m, index) => ({ modifier: m, index }))
    .filter((m) => m.modifier.templateId === id);
};

const unchangedValues = ref(cloneDeep(formValues));

const onSubmit = handleSubmit(async (values) => {
  const { isConfirmed } = await Alert.fire({
    text: t('reallyCreateNewVersion'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('createNewVersion'),
    denyButtonText: t('cancel'),
  });
  if (!isConfirmed) {
    return;
  }
  unchangedValues.value = cloneDeep(formValues);
  emit('onSubmit', values);
});

usePreventLeavingUnsavedForm(() => formHasChanged(unchangedValues.value, formValues));
</script>

<i18n lang="json">
{
  "en": {
    "templates": "Templates",
    "modifiers": "Modifiers",
    "name": "Name",
    "conditions": "Conditions",
    "addTemplate": "Add Template",
    "addModifier": "Add Modifier",
    "reallyDeleteTemplate": "All modifiers that use this template will be deleted too. Do you really want to delete this template?",
    "reallyDeleteModifier": "Do you really want to delete this modifier?",
    "reallyCreateNewVersion": "Do you really want to create a new pricing modifiers version? This will change the outcome of all future price calculations.",
    "createNewVersion": "Create new version",
    "delete": "Delete",
    "cancel": "Cancel",
    "editTemplates": "Edit templates",
    "hideTemplates": "Hide templates"
  },
  "de": {
    "templates": "Vorlagen",
    "modifiers": "Modifikatoren",
    "name": "Name",
    "conditions": "Bedingungen",
    "addTemplate": "Template hinzufügen",
    "addModifier": "Modifikator hinzufügen",
    "reallyDeleteTemplate": "Alle Modifikatoren, die dieses Template nutzen, werden auch gelöscht. Möchtest du dieses Template wirklich löschen?",
    "reallyDeleteModifier": "Möchtest du diesen Modifikator wirklich löschen",
    "reallyCreateNewVersion": "Möchtest du wirklich eine neue Version der Preismodifikatoren erstellen? Dies wird das Ergebnis aller zukünftigen Preisberechnungen ändern.",
    "createNewVersion": "Neue Version erstellen",
    "delete": "Löschen",
    "cancel": "Abbrechen",
    "editTemplates": "Vorlagen bearbeiten",
    "hideTemplates": "Vorlagen ausblenden"
  }
}
</i18n>
