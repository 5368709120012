import { type CreateClientConfig } from "@hey-api/client-fetch";
import { isValid, parseISO } from "date-fns";

const isoPattern =
  /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d+)?([Zz]|[+-]\d{2}:?\d{2})?)?$/;

export const createClientConfig: CreateClientConfig = (config) => ({
  ...config,
  responseTransformer: async (response: any) => {
    const deserializeDates = (value: any) => {
      if (
        value === null ||
        value === undefined ||
        (typeof value !== "object" && typeof value !== "string")
      ) {
        return value;
      }
      if (typeof value === "string" && isoPattern.test(value)) {
        const parsed = parseISO(value);
        if (isValid(parsed)) {
          return parsed;
        }
      }
      if (Array.isArray(value)) {
        value.forEach((item, i) => (value[i] = deserializeDates(item)));
        return value;
      }
      if (typeof value === "object") {
        for (const key in value) {
          if (value.hasOwnProperty(key)) {
            value[key] = deserializeDates(value[key]);
          }
        }
        return value;
      }
      return value;
    };
    return deserializeDates(response);
  },
});
