<template>
  <div class="space-y-4">
    <InputField
      v-if="template.conditions.allowedDiscountTimePeriod"
      :name="`modifiers.${modifierIndex}.conditions.allowedDiscountTimePeriod`"
      :label="t('pricingModifierConditions.allowedDiscountTimePeriod')"
      type="number"
      :max-fraction-digits="0"
      :min="1"
      class="w-full"
    />
    <MultiSelectField
      v-if="template.conditions.categories"
      :name="`modifiers.${modifierIndex}.conditions.categories`"
      :label="t('pricingModifierConditions.categories')"
      value-prop="value"
      mode="tags"
      :options="categoryOptions"
      class="w-full"
    />
    <InputField
      v-if="template.conditions.fromDayDuration"
      :name="`modifiers.${modifierIndex}.conditions.fromDayDuration`"
      :label="t('pricingModifierConditions.fromDayDuration')"
      type="number"
      :max-fraction-digits="0"
      :min="1"
      class="w-full"
    />
    <MultiSelectField
      v-if="template.conditions.makes"
      :name="`modifiers.${modifierIndex}.conditions.makes`"
      :label="t('pricingModifierConditions.makes')"
      value-prop="value"
      mode="tags"
      :options="makeOptions"
      class="w-full"
    />
    <InputField
      v-if="template.conditions.fromStationLoad"
      :name="`modifiers.${modifierIndex}.conditions.fromStationLoad`"
      :label="t('pricingModifierConditions.fromStationLoad')"
      type="number"
      :max-fraction-digits="2"
      :min="0"
      class="w-full"
    />
    <MultiSelectField
      v-if="template.conditions.stations"
      :name="`modifiers.${modifierIndex}.conditions.stations`"
      :label="t('pricingModifierConditions.stations')"
      value-prop="value"
      mode="tags"
      :options="stationOptions"
      class="w-full"
    />
    <TimePeriodForm v-if="template.conditions.timePeriod" :modifier-index="modifierIndex" />
    <MultiSelectField
      v-if="template.conditions.weekDays"
      :name="`modifiers.${modifierIndex}.conditions.weekDays`"
      :label="t('pricingModifierConditions.weekDays')"
      value-prop="value"
      mode="tags"
      :options="weekDayOptions"
      class="w-full"
    />
    <Divider />
    <InputField
      :name="`modifiers.${modifierIndex}.factor`"
      :label="t('modifier')"
      type="number"
      :max-fraction-digits="4"
      :min="0"
      class="w-full"
    />
    <Divider />
    <div class="flex justify-end gap-4">
      <CVButton @click="$emit('close')">{{ t('close') }}</CVButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import Divider from '@/components/Divider.vue';
import type { PricingModifierTemplateDto } from '@carvia/ros-client-types';
import InputField from '@/components/InputField.vue';
import MultiSelectField from '@/components/MultiSelectField.vue';
import { computed } from 'vue';
import { VehicleCategory } from '@/entities/vehicle-category.enum.ts';
import { useMakes } from '@/queries/use-makes.ts';
import { useStationsWithQuery } from '@/queries/use-stations.ts';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data.ts';
import { getLocalizedField } from '@/utils/get-localized-field.ts';
import TimePeriodForm from '@/views/pricing-modifiers/TimePeriodForm.vue';
import { formatDate } from '@/hooks/use-formatted-date.ts';

const { modifierIndex } = defineProps<{
  modifierIndex: number;
  template: PricingModifierTemplateDto;
}>();

defineEmits<{
  (e: 'close'): void;
}>();

const { t, locale } = useI18n();

const categoryOptions = computed(() =>
  Object.values(VehicleCategory).map((value) => ({
    label: t(`categories.${value}`),
    value,
  })),
);

const makesResult = useMakes();
const makeOptions = computed(
  () =>
    makesResult.data.value?.map((make) => ({
      label: make.name,
      value: make.id,
    })) ?? [],
);

const stationsResult = useStationsWithQuery({
  limit: 500,
});
const stations = useFlattenPaginatedData(stationsResult.data);
const stationOptions = computed(
  () =>
    stations.value?.map((station) => ({
      label: getLocalizedField(station.info, locale.value),
      value: station.id,
    })) ?? [],
);

const weekDayOptions = computed(() =>
  Array.from({ length: 7 }, (_, i) => ({
    label: formatDate(new Date(2025, 2, 3 + i), { weekday: 'long' }),
    value: i + 1,
  })),
);
</script>

<i18n lang="json">
{
  "en": {
    "modifier": "Modifier",
    "close": "Close"
  },
  "de": {
    "modifier": "Modifikator",
    "close": "Schließen"
  }
}
</i18n>
