<template>
  <div class="space-y-4">
    <InputField :name="`templates.${templateIndex}.name`" :label="t('name')" />
    <div>
      <InputLabel :label="t('conditions')" />
      <CheckboxField
        :name="`templates.${templateIndex}.conditions.allowedDiscountTimePeriod`"
        :label="t('pricingModifierConditions.allowedDiscountTimePeriod')"
      />
      <CheckboxField
        :name="`templates.${templateIndex}.conditions.categories`"
        :label="t('pricingModifierConditions.categories')"
      />
      <CheckboxField
        :name="`templates.${templateIndex}.conditions.fromDayDuration`"
        :label="t('pricingModifierConditions.fromDayDuration')"
      />
      <CheckboxField
        :name="`templates.${templateIndex}.conditions.makes`"
        :label="t('pricingModifierConditions.makes')"
      />
      <CheckboxField
        :name="`templates.${templateIndex}.conditions.fromStationLoad`"
        :label="t('pricingModifierConditions.fromStationLoad')"
      />
      <CheckboxField
        :name="`templates.${templateIndex}.conditions.stations`"
        :label="t('pricingModifierConditions.stations')"
      />
      <CheckboxField
        :name="`templates.${templateIndex}.conditions.timePeriod`"
        :label="t('pricingModifierConditions.timePeriod')"
      />
      <CheckboxField
        :name="`templates.${templateIndex}.conditions.weekDays`"
        :label="t('pricingModifierConditions.weekDays')"
      />
    </div>
    <Divider />
    <div class="flex justify-end gap-4">
      <CVButton @click="$emit('close')">{{ t('close') }}</CVButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import InputField from '@/components/InputField.vue';
import CheckboxField from '@/components/CheckboxField.vue';
import { useI18n } from 'vue-i18n';
import InputLabel from '@/components/InputLabel.vue';
import Divider from '@/components/Divider.vue';

defineProps<{
  templateIndex: number;
}>();

defineEmits<{
  (e: 'close'): void;
}>();

const { t } = useI18n();
</script>

<i18n lang="json">
{
  "en": {
    "name": "Name",
    "conditions": "Conditions",
    "close": "Close"
  },
  "de": {
    "name": "Name",
    "conditions": "Bedingungen",
    "close": "Schließen"
  }
}
</i18n>
