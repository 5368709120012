<template>
  <form class="space-y-4" @submit="handleSaveInsuranceCase">
    <div class="grid grid-cols-3 gap-4">
      <SelectField
        class="basis-1/3"
        :name="`insuranceProvider`"
        :label="t('insuranceProvider')"
        :options="insuranceProviderOptions"
      />
      <LabeledText v-if="initialInsuranceCase?.status" :label="t('status')">
        {{ t('insuranceCaseStatus.' + initialInsuranceCase?.status) }}
      </LabeledText>
      <CVButton
        v-if="initialInsuranceCase && !initialInsuranceCase.booking"
        @click.prevent="createBooking"
        >{{ t('bookInsuranceCase') }}</CVButton
      >
      <CVButton
        v-else-if="initialInsuranceCase?.booking"
        :to="{ name: 'bookingEdit', params: { id: initialInsuranceCase.booking.id } }"
        outline
      >
        {{ t('viewLinkedBooking') }}
      </CVButton>
    </div>

    <Divider />

    <div v-if="formValues.insuranceProvider">
      <AxaInsuranceDataForm v-if="formValues.insuranceProvider === 'axa'" />

      <Divider />
    </div>

    <div class="space-y-4">
      <div class="grid grid-cols-3 gap-4">
        <TextareaField name="additionalInfo" :label="t('additionalInfo')" class="col-span-2" />
      </div>
    </div>

    <Divider />

    <div class="space-y-4">
      <h2>{{ t('timeAndLocation') }}</h2>
      <div class="grid grid-cols-3 gap-4">
        <BookingRangePicker
          :start-date="formValues.startDate!"
          :end-date="formValues.endDate!"
          class="col-span-2"
          :start-date-title="t('startDate')"
          :end-date-title="t('endDate')"
          @change="handleTimeRangeChange"
        />
        <div class="col-start-1" data-testid="location">
          <InputLabel :label="t('pickupLocation')" />
          <SearchLocation
            :location="convertToGeocodedLocation(formValues.locationGeocodedAddress)"
            @update:location="handleLocationChange($event)"
          />
        </div>
      </div>
    </div>

    <Divider />

    <div class="space-y-4">
      <h2>{{ t('customerData') }}</h2>
      <CustomerDataForm name="customerData" hide-email hide-header />
      <div v-if="!initialInsuranceCase" class="grid grid-cols-3 gap-4">
        <InputField name="email" :label="t('email')" />
      </div>
    </div>

    <Divider />

    <div v-if="initialInsuranceCase" class="space-y-4">
      <h2>{{ t('linkedCustomerAccount') }}</h2>

      <div v-if="!isSwappingCustomer" class="flex items-center justify-between">
        <div class="flex items-center gap-2.5">
          <p>{{ t('customerAccount') }}:</p>
          <p class="rounded-lg bg-primary/20 px-3 py-1">
            {{ initialInsuranceCase?.customer?.email ?? t('noCustomerAccount') }}
          </p>
        </div>
        <div class="flex gap-4">
          <CVButton outline :disabled="isSaving" @click.prevent="isSwappingCustomer = true">{{
            initialInsuranceCase.customer?.id ? t('swapAccount') : t('linkAccount')
          }}</CVButton>
          <CVButton
            v-if="$can('manage', 'User') && initialInsuranceCase.customer"
            outline
            :disabled="isSaving"
            @click.prevent="viewAccount"
            >{{ t('viewAccount') }}</CVButton
          >
        </div>
      </div>

      <SwapCustomer
        v-if="isSwappingCustomer"
        :schema="userEditSchema"
        :initial-customer-data="initialInsuranceCase?.customerData"
        :swap-button-text="initialInsuranceCase?.customer ? t('swap') : t('add')"
        :is-saving="isSaving"
        @swap-customer="swapCustomer"
        @cancel="isSwappingCustomer = false"
      />

      <Divider />
    </div>

    <div class="flex justify-between">
      <CVButton
        v-if="initialInsuranceCase?.status === InsuranceCaseStatus.INITIAL"
        :disabled="isSaving"
        :is-loading="isDeleting"
        outline
        variant="error"
        @click.prevent="emit('delete')"
        >{{ t('delete') }}</CVButton
      >
      <div class="flex flex-1 justify-end">
        <CVButton :is-loading="isSaving">{{ t('save') }}</CVButton>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';
import CustomerDataForm from '@/components/booking-forms/customer/CustomerDataForm.vue';
import InputField from '@/components/InputField.vue';
import { useI18n } from 'vue-i18n';
import Divider from '@/components/Divider.vue';
import BookingRangePicker from '@/views/handover/components/overview/BookingRangePicker.vue';
import { DateTime } from 'luxon';
import SelectField from '@/components/SelectField.vue';
import { computed, ref } from 'vue';
import { createInsuranceCaseSchema } from '@/validation/insurance-case.schema';
import AxaInsuranceDataForm from './AxaInsuranceDataForm.vue';
import {
  type CreateInsuranceCaseDto,
  type InsuranceCase,
  InsuranceCaseStatus,
  Local,
  type LocationDto,
  type LocationGeocodedAddress,
  type UpdateInsuranceCaseDto,
} from '@carvia/ros-client-types';
import SearchLocation from '@/components/booking-forms/SearchLocation.vue';
import type { ExtendedGeocodedLocation } from '@/components/GooglePlacesInput.vue';
import InputLabel from '@/components/InputLabel.vue';
import { cloneDeep } from 'lodash';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form';
import { formHasChanged } from '@/hooks/use-form-has-changed';
import SwapCustomer from '@/components/booking-forms/customer/SwapCustomer.vue';
import { type User } from '@/entities/auth/user.entity';
import router from '@/router';
import { userEditSchema } from '@/validation/user.schema';
import { Alert } from '@/utils/alert';
import LabeledText from '@/components/LabeledText.vue';
import TextareaField from '@/components/TextareaField.vue';

const props = defineProps<{
  initialInsuranceCase?: InsuranceCase;
  isSaving?: boolean;
  isDeleting?: boolean;
}>();

const emit = defineEmits<{
  (e: 'submit', value: CreateInsuranceCaseDto | UpdateInsuranceCaseDto): void;
  (e: 'delete'): void;
}>();

const { t } = useI18n();
const isSwappingCustomer = ref(false);

const initialStartDate = DateTime.now()
  .plus({ days: 1 })
  .set({ hour: 11, minute: 0, second: 0, millisecond: 0 });

const convertToGeocodedLocation = (location: LocationGeocodedAddress | undefined | null) => {
  if (!location) return undefined;
  return {
    address: { displayName: location.displayName },
    coordinates: location.location,
  };
};

const handleLocationChange = (location: ExtendedGeocodedLocation | null) => {
  setFieldValue(
    'locationGeocodedAddress',
    location
      ? {
          location: location.coordinates as LocationDto,
          displayName: location.address?.displayName ?? '',
        }
      : location,
  );
};

const swapCustomer = async (customer: User) => {
  emit('submit', { customerId: customer.id });
  isSwappingCustomer.value = false;
};

const viewAccount = () => {
  if (!props.initialInsuranceCase?.customer) return;
  router.push({
    name: 'user',
    params: { id: props.initialInsuranceCase.customer?.id },
  });
};

const initialValues = ref<CreateInsuranceCaseDto | UpdateInsuranceCaseDto>({
  startDate: props.initialInsuranceCase?.startDate ?? initialStartDate.toJSDate(),
  endDate: props.initialInsuranceCase?.endDate ?? initialStartDate.plus({ days: 2 }).toJSDate(),
  locationGeocodedAddress: props.initialInsuranceCase?.locationGeocodedAddress,
  customerData: {
    ...props.initialInsuranceCase?.customerData,
    firstName: props.initialInsuranceCase?.customerData?.firstName ?? '',
    lastName: props.initialInsuranceCase?.customerData?.lastName ?? '',
    street: props.initialInsuranceCase?.customerData?.street ?? '',
    zip: props.initialInsuranceCase?.customerData?.zip ?? '',
    city: props.initialInsuranceCase?.customerData?.city ?? '',
    country: props.initialInsuranceCase?.customerData?.country ?? '',
    preferredLocal: props.initialInsuranceCase?.customerData?.preferredLocal ?? Local.DE_DE,
  },
  insuranceProvider: props.initialInsuranceCase?.insuranceProvider,
  insuranceData: props.initialInsuranceCase?.insuranceData ?? undefined,
  additionalInfo: props.initialInsuranceCase?.additionalInfo ?? undefined,
});

const {
  handleSubmit,
  values: formValues,
  setFieldValue,
} = useForm<CreateInsuranceCaseDto | UpdateInsuranceCaseDto>({
  initialValues: initialValues.value,
  validationSchema: createInsuranceCaseSchema,
});

const handleSaveInsuranceCase = handleSubmit((values) => {
  emit('submit', values);
  initialValues.value = cloneDeep(values);
});

usePreventLeavingUnsavedForm(() => formHasChanged(initialValues.value, formValues));

const insuranceProviderOptions = computed(() => [
  {
    label: t('AXA'),
    value: 'axa',
  },
]);

const handleTimeRangeChange = (startDate: string, endDate: string) => {
  setFieldValue('startDate', new Date(startDate));
  setFieldValue('endDate', new Date(endDate));
};

const createBooking = () => {
  if (!props.initialInsuranceCase) return;
  if (!props.initialInsuranceCase.locationGeocodedAddress) {
    return Alert.fire({
      html: t('locationMissingText'),
      icon: 'error',
      confirmButtonText: t('ok'),
    });
  }
  router.push({
    name: 'createBookingFromInsuranceCase',
    params: {
      insuranceCaseId: props.initialInsuranceCase.id,
    },
  });
};
</script>

<i18n lang="json">
{
  "en": {
    "pickupLocation": "Pickup location",
    "dropoffLocation": "Dropoff location",
    "timeAndLocation": "Time and location",
    "startDate": "Start date",
    "endDate": "End date",
    "email": "Email",
    "insuranceProvider": "Insurance provider",
    "customerData": "Customer data",
    "linkedCustomerAccount": "Linked customer account",
    "customerAccount": "Customer account",
    "noCustomerAccount": "No account linked",
    "linkAccount": "Link account",
    "viewAccount": "View account",
    "swapAccount": "Swap Account",
    "swap": "Swap",
    "add": "Add",
    "bookInsuranceCase": "Book insurance case",
    "viewLinkedBooking": "View linked booking",
    "locationMissingText": "<strong>Pickup</strong> and <strong>dropoff locations</strong> must be set, to create a booking.",
    "delete": "Delete",
    "status": "Status",
    "insuranceCaseStatus": {
      "INITIAL": "Initial",
      "BOOKED": "Booked",
      "HANDED_OVER": "Handed over",
      "RETURNED": "Returned",
      "CLOSED": "Closed"
    },
    "additionalInfo": "Additional Info"
  },
  "de": {
    "pickupLocation": "Abholort",
    "dropoffLocation": "Rückgabeort",
    "timeAndLocation": "Zeit und Ort",
    "startDate": "Startdatum",
    "endDate": "Enddatum",
    "email": "E-Mail",
    "insuranceProvider": "Versicherungsanbieter",
    "customerData": "Kundendaten",
    "linkedCustomerAccount": "Verknüpftes Kundenkonto",
    "customerAccount": "Kundenkonto",
    "noCustomerAccount": "Kein verknüpftes Konto",
    "linkAccount": "Konto verknüpfen",
    "viewAccount": "Konto ansehen",
    "swapAccount": "Konto austauschen",
    "swap": "Austauschen",
    "add": "Hinzufügen",
    "bookInsuranceCase": "Versicherungsfall buchen",
    "viewLinkedBooking": "Verknüpfte Buchung ansehen",
    "locationMissingText": "<strong>Abhol-</strong> und <strong>Rückgabeort</strong> müssen gesetzt sein, um eine Buchung zu erstellen.",
    "delete": "Löschen",
    "status": "Status",
    "insuranceCaseStatus": {
      "INITIAL": "Initial",
      "BOOKED": "Gebucht",
      "HANDED_OVER": "Übergeben",
      "RETURNED": "Zurückgegeben",
      "CLOSED": "Abgeschlossen"
    },
    "additionalInfo": "Zusätzliche Infos"
  }
}
</i18n>
