<template>
  <button
    class="flex items-center justify-between gap-4 rounded-lg p-2 text-left text-orange-900/80"
    :class="{
      'bg-primary/10 hover:bg-primary/20': !hasError,
      'bg-error/20 hover:bg-error/30': hasError,
    }"
  >
    <div class="handle cursor-grab">
      <Icon name="bars-3" class="text-black/20" />
    </div>
    <div
      v-for="key in Object.keys(modifier.conditions).sort((a, b) => (a < b ? -1 : 1))"
      :key="key"
      class="flex flex-1 flex-col gap-1"
    >
      <div class="text-xs text-orange-900/60">{{ t('pricingModifierConditions.' + key) }}</div>
      <div>{{ printCondition(key as keyof PricingModifierConditionsDto) }}</div>
    </div>
    <div class="flex flex-col gap-1">
      <div class="text-xs text-orange-900/60">{{ t('modifier') }}</div>
      <div>{{ formatFloat(modifier.factor, 4) }}</div>
    </div>
    <button
      class="flex size-6 items-center justify-center rounded-md hover:bg-white"
      @click.stop="$emit('delete')"
    >
      <XMarkIcon class="size-4 text-error" />
    </button>
  </button>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/solid';
import type { PricingModifierConditionsDto, PricingModifierDto } from '@carvia/ros-client-types';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/hooks/use-formatted-date.ts';
import { useMakes } from '@/queries/use-makes.ts';
import { useStationsWithQuery } from '@/queries/use-stations.ts';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data.ts';
import { getLocalizedField } from '@/utils/get-localized-field.ts';
import { formatFloat } from '@/utils/format-numbers.ts';

const { modifier } = defineProps<{
  modifier: PricingModifierDto;
  hasError?: boolean;
}>();

defineEmits<{
  (e: 'delete'): void;
}>();

const { t, locale } = useI18n();

const makesResult = useMakes();
const stationsResult = useStationsWithQuery({
  limit: 500,
});
const stations = useFlattenPaginatedData(stationsResult.data);

const printCondition = (conditionKey: keyof PricingModifierConditionsDto) => {
  if (conditionKey === 'timePeriod') {
    if (!modifier.conditions.timePeriod) {
      return '-';
    }
    const fromMonth = formatDate(
      new Date(2025, (modifier.conditions.timePeriod.fromMonth ?? 1) - 1),
      {
        month: 'long',
      },
    );
    const fromYear = !modifier.conditions.timePeriod.isYearly
      ? modifier.conditions.timePeriod.fromYear
      : undefined;
    const toMonth = formatDate(new Date(2025, (modifier.conditions.timePeriod.toMonth ?? 1) - 1), {
      month: 'long',
    });
    const toYear = fromYear
      ? modifier.conditions.timePeriod.fromMonth <= modifier.conditions.timePeriod.toMonth
        ? fromYear
        : fromYear + 1
      : undefined;
    return !fromYear
      ? `${fromMonth} - ${toMonth} (${t('yearly')})`
      : `${fromMonth} ${fromYear} - ${toMonth} ${toYear}`;
  }
  if (conditionKey === 'stations') {
    return (
      stations.value
        .filter((s) => modifier.conditions[conditionKey]?.includes(s.id))
        .map((s) => getLocalizedField(s.info, locale.value))
        .join(', ') ?? '-'
    );
  }
  if (conditionKey === 'makes') {
    return (
      makesResult.data.value
        ?.filter((m) => modifier.conditions[conditionKey]?.includes(m.id))
        .map((m) => m.name)
        .join(', ') ?? '-'
    );
  }
  if (conditionKey === 'weekDays') {
    return (
      modifier.conditions[conditionKey]
        ?.map((wd) => formatDate(new Date(2025, 2, 2 + wd), { weekday: 'long' }))
        .join(', ') ?? '-'
    );
  }
  if (conditionKey === 'categories') {
    return modifier.conditions[conditionKey]?.map((c) => t('categories.' + c)).join(', ') ?? '-';
  }
  if (conditionKey === 'fromStationLoad') {
    return modifier.conditions[conditionKey] ? formatFloat(modifier.conditions[conditionKey]) : '-';
  }
  return modifier.conditions[conditionKey] ?? '-';
};
</script>

<i18n lang="json">
{
  "en": {
    "modifier": "Modifier",
    "yearly": "yearly"
  },
  "de": {
    "modifier": "Modifier",
    "yearly": "jährlich"
  }
}
</i18n>
