import { Service } from './http-service';
import type { CreatePricingModifiersDto, PricingModifiers } from '@carvia/ros-client-types';

class PricingModifiersService extends Service<
  PricingModifiers,
  CreatePricingModifiersDto,
  undefined
> {
  async getLatest() {
    const res = await this.httpClient.get<PricingModifiers>(this.endpoint + '/latest');
    return res.data;
  }
}

export const pricingModifiersService = new PricingModifiersService('/pricing-modifiers');
