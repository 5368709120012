import { computed, type MaybeRefOrGetter, toValue } from 'vue';
import { DateTime, type DateTimeFormatOptions } from 'luxon';
import { i18n } from '@/i18n';

export const useFormattedDate = (
  date?: MaybeRefOrGetter<Date | string | null | undefined>,
  format: MaybeRefOrGetter<string | DateTimeFormatOptions> = DateTime.DATETIME_MED,
  emptyString = '',
) => {
  return computed(() => formatDate(toValue(date), toValue(format), emptyString));
};

export const formatDate = (
  date?: Date | string | null,
  format: string | DateTimeFormatOptions = DateTime.DATETIME_MED,
  emptyString = '',
) => {
  if (!date) return emptyString;
  const dateTime = typeof date === 'string' ? DateTime.fromISO(date) : DateTime.fromJSDate(date);
  if (typeof format === 'string') {
    return dateTime.toFormat(format);
  }
  return dateTime.toLocaleString(format, { locale: toValue(i18n.global.locale) });
};
